import React from "react";
import {graphql, Link} from "gatsby"

import Layout from "../Layout";
import { Helmet } from "react-helmet";

const statics = [
  { name: "Neubau eines Produktions- und Logistikgebäudes", year: 2008 },
  { name: "Neubau eines Servicecenters", year: 2008 },
  { name: "Neubau eines Distributionscenters (Bauüberwachung)", year: 2006 },
  { name: "Filtersilo einer Kupferhütte", year: 2008 },
  { name: "Neubau eines Wohn-und Bürohauses", year: 2009 },
  { name: "Neubau einer Automobil-Werkstatt", year: 2009 },
  { name: "Anbau an ein Einfamilienhaus", year: 2009 },
  { name: "Neubau eines Verwaltungsgebäudes", year: 2009 },
  { name: "Entkernung eines Gründerzeithauses", year: 2009 },
  { name: "Erweiterung einer Ölmühle", year: 2010 },
  { name: "Umbau einer Lüftungsanlage", year: 2010 },
  { name: "Erweiterung eines Produktionsgebäudes", year: 2011 },
  { name: "Neubau eines Schwenkförderers", year: 2011 },
  { name: "Neubau eines Geschäftshauses mit Tiefgarage", year: 2011 },
  { name: "Umbau eines Autohauses zu einem Cafe", year: 2011 },
  { name: "Umnutzungskonzept für eine Eisenbahnbrücke", year: 2011 },
  { name: "Tragfähigkeitsnachweis für eine Flussschiffbeladung", year: 2012 },
  { name: "Optimierung einer Produktionshalle", year: 2012 },
  { name: "Einbauten in einer Industrieanlage", year: 2012 },
  { name: "Erweiterung einer Chemieproduktion", year: 2012 },
  { name: "Kernsanierung eines Stadthauses", year: 2013 },
];

const Ref = ({r}) => {
    const text = `${r.name} (${r.year})`
    if (r.url) {
        return <li><Link to={r.url}>{text}</Link></li>
    }
    return <li>{text}</li>
}

const Page = ({data}) => {
    const pages = data.allMarkdownRemark.edges.map(({node}) => ({
        name: node.frontmatter.title,
        year: node.frontmatter.year,
        url: `/referenzen/${node.parent.name}`
    }))

    const refs = pages.concat(statics).sort((a,b) => a.year - b.year)

  return (
    <Layout>
    <Helmet>
      <title>Referenzen – braack</title>
    </Helmet>
      <h2>Referenzen</h2>
      <ul>
          {refs.map(r => <Ref r={r} key={r.name} />)}
      </ul>
    </Layout>
  );
};

export default Page;

export const query = graphql`
  {
    allMarkdownRemark(filter: {fileAbsolutePath: {glob: "**/referenzen/*.md"}}) {
      edges {
        node {
          parent {
            ... on File {
              name
            }
          }
          frontmatter {
              title
              year
          }
        }
      }
    }
  }
`